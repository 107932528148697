// ----------------------------
//  BASE HTML ELEMEMENTS
// ----------------------------

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

html {
  width: 100%;
  height: 100%;
}

body {
  font-family: 16px;
  line-height: 1.4;
  font-family: $font-base;
  overflow-x: hidden;

  .lp-preload {
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease;
  }

  &.loading {
    .lp-preload {
      opacity: 1;
      visibility: visible;
      transition: 0.3s ease-in;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
}

p,
li {
  line-height: 1.4;
}

img {
  max-width: 100%;
  height: auto;
}

input,
textarea {
  border-radius: 0px;
}

input[type="text"],
input[type="email"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a {
  color: $purple;
  text-decoration: none;
}

section {
  padding-top: 60px;
  position: relative;
}

select,
option {
  background: #fff;
  border-radius: 0px;
  box-shadow: none;
}

.hidden {
  display: none;
}

.text-small {
  font-size: 14px;
}

.text-center {
  text-align: center;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.btn {
  background: #000000;
  border: none;
  color: #fff;
  padding: 20px 36px 14px;
  font-family: $font-nonbase-regular;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  @include tablet {
    font-size: 20px;
    line-height: 20px;
  }

  &:hover {
    background: #000;
    color: $blueDark;
  }
}

.text-white {
  color: #fff;
}

.btn-small {
  font-size: 14px;
  padding: 12px 28px 10px;
}

.lp-preload {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  font-weight: bold;
  z-index: 9999;
  background: $blueDark;
  text-align: center;

  // background: #008cd2;
  // background-position: center center;
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-image: url('../assets/img/bg-checkAge-small.jpg');

  // @include laptop {
  //     background-image: url('../assets/img/bg-checkAge.jpg');
  // }
}

.lp-preload-center {
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lp-progress-percent {
  font-size: 28px;
  font-weight: bold;
  font-style: italic;
  margin-bottom: 10px;
  display: inline-block;
  width: 200px;
  color: #000000;
}

.lp-progress-bar {
  width: 260px;
  height: 20px;
  position: relative;

  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 20px;
    background: #000000;
  }
}

.preloading {
  display: none;
}

.row {
  @extend .clearfix;
}

.wrapper {
  max-width: 1168px;
  padding-left: 28px;
  padding-right: 28px;
  width: 100%;
  margin: 0 auto;
  @extend .clearfix;

  @include laptop {
  }
}

.lp-outer {
}

.header {
}

.footer {
}

.parsley-errors-list {
  margin: 0;
  margin-top: 6px;
  padding: 0;

  li {
    color: red;
    margin: 0;
    padding: 0;
    font-weight: bold;
  }
}
