.footer {
  position: relative;
  padding-top: 60px;
  padding-bottom: 20px;
  // background: url('/assets/images/kv-straplineBlue.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 1200px auto;
  //   background: $black;
  font-family: $font-nonbase-regular;
  color: $blueDark;
}

.footer--thankyou {
  padding-top: 20px;
  background: none;
}

.footer-metaLinks {
  margin: 0;
  padding: 0;
  display: block;

  li {
    display: inline-block;
    margin-right: 6px;
    width: 100%;
    text-align: center;

    @include tablet {
      width: auto;
      text-align: left;
      margin-right: 26px;
    }

    a {
      color: $blueDark;
      text-shadow: 0px 0px 1px $blueDark;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.footer-left {
  @include tablet {
    width: 70%;
    float: left;
  }
}

.footer-right {
  width: 100%;

  @include tablet {
    width: 30%;
    float: left;
  }
}

.footer-copyright {
  margin-top: 10px;
  margin-top: 10px;
  text-shadow: 0px 0px 1px $blueDark;
  display: block;
  width: 100%;
  text-align: center;

  @include tablet {
    text-align: left;
  }
}

.footer-wiseDrinking {
  width: 100%;
  text-align: center;
  display: block;

  img {
    max-width: 232px;
    display: inline-block;
  }

  @include tablet {
    float: right;
    text-align: right;
  }
}
