// ----------------------------
//	TOOLS & UTILS
// ----------------------------

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

$font-base: "Arial", Arial, sans-serif;

$font-nonbase-regular: "Arial", Arial, sans-serif;
$font-nonbase-black: "Arial", Arial, sans-serif;

$black: #010101;
$purple: #000;
$blue: #008fd4;
$blueDark: #041679;
$yellow: #fdff06;
$red: #ff2233;
$pink: #e41395;

$CubicEaseIn: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$CubicEaseOut: cubic-bezier(0.165, 0.84, 0.44, 1);
$CubicEaseInOut: cubic-bezier(0.645, 0.045, 0.355, 1);
$QuadEaseIn: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$QuadEaseOut: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$QuadEaseInOut: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ExpoEaseOut: cubic-bezier(0.19, 1, 0.22, 1);
$ExpoEaseInOut: cubic-bezier(1, 0, 0, 1);

@mixin transition($property) {
  -webkit-transition: $property;
  -moz-transition: $property;
  -o-transition: $property;
  transition: $property;
}

@mixin transform($transform) {
  transform: $transform;
  -webkit-transform: $transform;
  -o-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
}

@mixin transition($transition...) {
  // defining prefixes so we can use them in mixins below
  $prefixes: ("-webkit", "-ms", "-o", "");
  @each $prefix in $prefixes {
    #{$prefix}-transition: $transition;
  }

  transition: $transition;
}

$screen-md: 767px;
$screen-lg: 1024px;

@mixin tablet {
  @media screen and (min-width: $screen-md) {
    @content;
  }
}

@mixin laptop {
  @media screen and (min-width: $screen-lg) {
    @content;
  }
}
