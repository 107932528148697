.section {
  padding: 90px 0;
}

.section__kv {
  padding-top: 96px;
  background-color: #2d2010;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  // background-image: url('../assets/images/bg-checkAge-small.jpg');
  overflow: hidden;
  position: relative;

  @include tablet {
    height: 1000px;
  }

  @include laptop {
    height: 750px;
    // background-image: url('../assets/images/bg-checkAge.jpg');
  }

  .wrapper {
    max-width: 1500px;
    position: relative;

    @include tablet {
      padding-bottom: 0px;
      height: 750px;
    }
  }
}

.section__kvDecor {
  position: absolute;
  height: 60px;
  width: 100%;
  bottom: -20px;
  left: 0px;
  z-index: 20;
  // background: url('../assets/images/kv-bottomWhite.png') center top;
}

.section__kvCar {
  margin-top: 30px;
  margin-right: -14px;

  @include tablet {
    margin-top: 0;
    margin-right: 0;
    top: auto;
    right: 0;
    bottom: -10px;
    position: absolute;
    display: block;
    max-width: 100%;
    right: -200px;
    top: -10px;
    bottom: auto;
  }

  @include laptop {
    right: 0;
  }

  @media screen and (min-width: 1500px) {
    right: 180px;
  }
}

.section__kvBottle {
  position: absolute;
  right: auto;
  top: auto;
  bottom: -140px;
  z-index: 9;
  width: 280px;
  transform: rotate(-6deg);

  @include tablet {
    left: auto;
    bottom: -190px;
    transform: rotate(0deg);
    width: 460px;
  }

  @include laptop {
    top: 0;
    right: 5%;
  }
}

.section__kvStage {
  z-index: 10;

  @include tablet {
    margin-top: -60px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding-right: 0px;
    padding-bottom: 140px;
    transform: translate(-50%, -50%);
  }

  @include laptop {
    padding-bottom: 0px;
    padding-right: 280px;
  }

  @media (min-width: 1200px) {
    padding-right: 400px;
  }
}

.section__kvSlogan {
  width: 100%;
  max-width: 512px;
  margin: 30px auto 20px auto;
  position: relative;
  padding-right: 20px;

  @include tablet {
    width: 512px;
  }

  img {
    position: relative;
    z-index: 9;
  }

  span {
    text-align: center;
    width: 100%;
    display: block;
    color: #000000; // old color
    text-shadow: 2px 2px 0px #00316c;
    font-family: $font-nonbase-black;

    @include tablet {
      font-size: 50px;
    }

    &.hidden {
      display: none;
    }
  }
}

.section__kvSloganBg {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  // background-image: url('../assets/images/kv-textBg-final.png');
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 540px) {
    background-size: cover;
  }
}

.section__kvInfo {
  color: #ffffff;
  text-align: center;
  position: relative;
  margin-top: 10px;

  @include tablet {
    margin-top: 20px;

    span {
      display: block;
    }
  }
}

.section__kvInfoOuter {
  color: #ffffff;
  padding-right: 0px;
  padding-bottom: 300px;
  position: relative;
  z-index: 9;

  @include tablet {
    padding-right: 0;
    padding-bottom: 0px;
    max-width: 700px;
    width: 700px;
  }

  @include laptop {
    padding-bottom: 100px;
  }
}

.section__kvTextRed {
  color: $red;
  text-transform: uppercase;
  margin-bottom: 6px;
  letter-spacing: 1px;
  font-family: $font-nonbase-black;

  @include tablet {
    font-size: 54px;
  }
}

.section__kvTextLead {
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0px 0px 1px #fff, 3px 3px 1px $black, 4px 4px 1px rgba(252, 255, 0, 0.7), 6px 6px 1px $black,
    7px 7px 1px rgba(252, 255, 0, 0.4);
  font-family: "roc-grotesk-wide", sans-serif;
  font-weight: 600;
  font-style: normal;
  margin-top: 4px;
  margin-bottom: 20px;
  font-size: 28px;

  span {
    @include tablet {
      font-size: 60px;
      display: block;
    }
  }
}

.section__kvTextSubtitle {
  font-family: "roc-grotesk-wide", sans-serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  // text-shadow: 0 0 1px #fff;
  margin-top: 0;
  margin-bottom: 0;

  @include tablet {
    font-size: 32px;

    span {
      display: block;
    }
  }
}

.section__kvDesc {
  max-width: 460px;
}

.section__kvInfo--special {
  margin-top: 0px;
  font-size: 22px;
  font-family: $font-nonbase-regular;
}

.section__kvInfo--large {
  @include tablet {
    font-size: 22px;
  }
}

.visible-konkurs {
  display: none !important;
}

.section__kvCTAOuter {
  text-align: left;
}

.section__kvCTA {
  display: inline-block;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0px;
  background: $pink;

  @include laptop {
    min-width: 310px;
  }

  &:hover {
    color: $pink;
    background: #fff;
  }
}

.section__prizes {
  overflow-x: hidden;
}

.section__item {
  width: 100%;
  margin-top: 30px;

  .section__itemTitleRedBG {
    margin-bottom: 20px;
  }

  @include laptop {
    margin-top: 0;
    width: 50%;
    float: left;
  }
}

.section__item--fullWidth {
  @include laptop {
    width: 100%;
  }
}

.section__itemTitleRedBG {
  display: inline-block;
  width: 100%;
  margin-top: 0;
  text-align: center;
  color: #000000; // old color
  // font-family: $font-nonbase-black;
  padding-left: 4px;
  padding-right: 4px;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 700;
  // text-shadow: 2px 2px 0px #000;
  font-size: 22px;
  position: relative;

  @include tablet {
    font-size: 30px;
  }

  span {
    position: relative;
  }

  &:before {
    content: "";
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    height: 106px;
    width: 100%;
    width: calc(100% + 28px);
    // background-image: urL('../assets/images/bg-title1.png');
    background-position: center center;
    background-repeat: no-repeat;

    @include tablet {
      width: 480px;
    }
  }
}

.section__itemTitleRedBG--2 {
  margin-top: 60px;

  @include laptop {
    margin-top: 0;
  }

  &:before {
    width: 100%;
    width: calc(100% + 28px);
    // background-image: urL('../assets/images/bg-title2.png');

    @include tablet {
      width: 568px;
    }
  }
}

.section__itemImg {
  position: relative;
  z-index: 9;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: auto;
  max-width: 596px;

  @include laptop {
    max-width: 540px;
  }
}

.section__itemInfo {
  text-align: center;
  font-size: 16px;
  font-family: $font-nonbase-regular;
  text-transform: uppercase;
  font-style: italic;
  font-weight: bold;
  margin: 40px 0px 0px 0px;
  color: $red;

  @include tablet {
    font-size: 21px;
  }

  span {
    @include tablet {
      display: block;
    }
  }
}

.section__itemDisclaimer {
  text-align: center;
}

.section__attendee {
  max-width: 1600px;
  margin: 0 auto;
  background-color: $pink;
  background-repeat: repeat-y;
  background-position: center top;
  // background-image: url('../assets/images/bg-checkAge-small.jpg');
  position: relative;
  padding-bottom: 70px;
  // margin-bottom: -80px;
  z-index: 9;
  min-height: 200px;

  @include laptop {
    // background-image: url('../assets/images/bg-checkAge.jpg');
  }
}

.section__attendeeInner {
  max-width: 730px;
  margin: 0 auto;
  background: $blueDark;
  padding: 60px 20px 36px;
}

.section__attendeeSubtitle {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-style: italic;
  // color: $red;

  @include tablet {
    font-size: 22px;
  }
}

.section__title {
  text-align: center;
  color: $black;
  text-transform: uppercase;
  font-family: $font-nonbase-black;
  font-size: 42px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 26px;

  @include tablet {
    font-size: 40px;

    span {
      display: block;
    }
  }

  @include laptop {
    margin-bottom: 60px;
    font-size: 60px;
  }
}

.section__attendeeTitle {
  color: #fff;
  margin-bottom: 10px;
  font-size: 30px;

  @include tablet {
    font-size: 40px;
  }

  @include laptop {
    font-size: 50px;
  }
}

.section__attendeeDesc {
  text-align: center;
  display: block;
  margin: 0 auto;
  max-width: 430px;
  width: 100%;
}

.section__attendeeForm {
  max-width: 490px;
  margin: 0 auto;
  color: #000000; // old color

  .row {
    margin-bottom: 16px;
  }

  .btn {
    background: $pink;
    margin: 0 auto;
    display: block;
    text-align: center;
  }

  label {
    color: #fff;
    margin-bottom: 10px;
    display: block;
  }

  .section__formInput,
  input[type="text"],
  input[type="email"],
  textarea,
  select {
    width: 100%;
    outline: 0;
    border: 0;
    padding: 10px 12px;
    border-radius: 0;
    box-shadow: none;
    background: #fff;
    font-weight: bold;
  }

  select {
    width: auto;
    padding-right: 10px;
  }
}

.form__birthOuter {
  vertical-align: top;
  display: inline-block;

  @media (max-width: 768px) {
    .parsley-errors-list {
      max-width: 60px;
    }
  }

  &.mt-formYearMobile {
    @media (max-width: 500px) {
      display: block;
      width: 96px;
      max-width: 96px;
      margin-top: 14px;
    }
  }

  @media (min-width: 350px) {
    margin-right: 10px;
  }
}

.form__birthBG {
  background: #fff;
  padding-right: 10px;
}

.form__rowUpload {
  margin-bottom: 30px;
}

.form__rowUpload__textBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  label {
    margin-top: 8px;
    padding-right: 20px;
  }
}

.form__rowUpload__button {
  position: relative;
  width: 64%;
  margin-left: 20px;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
  }
}

.form__uploadMessage {
  .error {
    color: red;
    text-shadow: 0 0 1px red;
  }
}

.form__imagePreview {
  margin-top: em(4);
  margin-bottom: em(20);

  p {
    font-size: em(15);
  }

  img {
    max-height: 400px;
  }
}

.form__rowUpload__urlPreloader {
  margin-top: em(14);
  margin-bottom: em(14);

  img {
    max-width: 44px;
    display: block;
    margin: 0 auto;
  }
}

.section__formCheckbox {
  position: relative;

  input[type="checkbox"] {
    position: absolute;
    top: 4px;
    left: 0px;
    visibility: hidden;
    opacity: 0;
  }

  input:checked + label {
    &:before {
      // background: #fff;
    }
  }

  label {
    padding-left: 34px;
    font-size: 14px;
    position: relative;
    cursor: pointer;

    i {
      font-size: 12px;
    }

    a {
      color: #ffffff; // old color
      text-decoration: underline;
    }

    &:before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      display: block;
      left: 0;
      top: 0px;
      border: 2px solid $pink;
      background: #fff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    }
  }
}

.section__formMessage {
  text-align: center;

  .success {
    color: #00f70c;
    font-weight: bold;
    font-size: 26px;
    font-family: $font-nonbase-regular;
    padding-bottom: 30px;
  }

  .error {
    color: red;
    padding-bottom: 30px;
  }
}

.section__winnersSwitcher {
  margin: 0;
  margin-bottom: 36px;
  padding: 0;
  list-style-type: none;
  text-align: center;

  @include laptop {
    margin-bottom: 76px;
  }

  li {
    display: inline-block;
    margin-right: 14px;
    margin-bottom: 14px;

    button {
      border: 0;
      outline: 0;
      box-shadow: none;
      cursor: pointer;
      display: block;
      text-transform: uppercase;
      font-family: $font-nonbase-black;
      background: #be1623;
      color: #000000; // old color
      padding: 10px 30px;
      font-size: 14px;
    }

    &.disabled {
      button {
        background: #c2c2c2;
      }
    }

    &.active {
      button {
        background: #008fd5;
      }
    }
  }
}

.section__itemWinnersEntries {
  padding-bottom: 40px;
  width: 100%;
  display: block;
  @extend .clearfix;

  span {
    text-align: center;
    display: inline-block;
    width: 100%;
    float: left;

    @include tablet {
      width: 50%;
    }
  }
}

.contestResults {
  display: none;
  @extend .row;

  &.active {
    display: block;
  }
}

.section__itemWinnersEntries--fullWidth {
  span {
    width: 100%;
  }
}

.section__partner {
  background: $blueDark;

  svg {
    height: 70px;
    display: block;
    margin: 0 auto;

    @include laptop {
      height: 140px;
    }
  }
}

.section__partnerInner {
  text-align: center;
  p,
  a {
    color: #fff;
  }

  @include laptop {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.section__partnerCTA {
  margin-left: 20px;
  display: block;
  margin-top: 40px;

  @include laptop {
    display: inline-block;
    margin-top: 0;
    margin-left: 120px;
  }
}
