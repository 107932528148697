.age-check {
    display: block;
    top: 0;
    left: 0;
    color: #FFF;
    z-index: 9998;
    width: 100%;
    min-width: 100%;
    min-height: 768px;
    min-height: 100vh;
    text-align: left;
    background: $black;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    // background-image: url('/assets/images/img/bg-checkAge-small.jpg');
    position: fixed;

    @include laptop {
        // background-image: url('/assets/images/img/bg-checkAge.jpg');
    }
}

.age-check__inner {
    display: inline-block;
    height: auto;
    position: relative;
    padding: 40px 24px;
    box-sizing: border-box;
    background: $black;
    box-shadow: 10px 10px 1px $yellow;

    @media (min-height: 600px) {
        position: absolute;
        width: 100%;
        max-width: 400px;
        padding: 40px 40px 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @include tablet {
        padding: 60px 60px 20px;
        max-width: 680px;
    }

    
}


.age-check a {
    color: #FFF;
}

.age-check .heading--big {
    font-family: $font-nonbase-black;
    margin: 20px 0px 30px;
    font-size: 28px;
    font-style: italic;
    color: $yellow;
    text-transform: uppercase;

    @include tablet {
        margin: 30px 0px;
        font-size: 54px;
    }
}

.age-check .age-check__logo {
    display: block;
    margin: 0 auto;
    margin-top: 40px;
    max-width: 220px;
    
    // @media (min-height: 600px) {
    //     margin-top: 0;
    //     position: absolute;
    //     margin: 0 auto;
    //     right: 0;
    //     left: 0;
    //     bottom: -60px;
    // }
}

.age-check .small {
    font-size: 12px;
}

.age-check .small a {
    color: #FFF;
    text-decoration: underline;
}

.age-check img {
    max-width: 300px;
}

.age-check .input-group {
    display: inline-block;
    margin-right: 10px;
    width: auto !important;

    @include tablet {
        margin-right: 20px;
    }
}

.age-check .input-group.last {
    margin-right: 0;
}

.age-check span.age-check__title {
    font-size: 14px;
    font-weight: bold;
    color: #FFF;
}

.age-check__inner__top {
    margin: 0px;
    font-family: $font-nonbase-regular;
    font-size: 13px;
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
}

.age-check__inner__bottom {
    display: inline-block;
    color: #FFF;
    margin: 10px 0px 0px;
    font-size: 11px;
}

.age-check__inner .form {
    display: inline-block;
    max-width: 960px;
    width: 100%;
}

.age-check input {
    font-family: $font-nonbase-regular;
    font-size: 18px;
    background: transparent;
    border: none;
    background: #fff;
    color: $black;
    border: #FFF 2px solid;
    text-align: center;
    border-radius: 0;
    padding: 8px 0;
    font-style: italic;
    width: 55px;
    box-shadow: 6px 6px 1px $yellow;
    
    @include tablet {
        padding: 12px 0;
        width: 80px;
        width: 140px;
        font-size: 50px;
    }

    &.input-birthYear {
        width: 90px;
        
        @include tablet {
            width: 208px;
            // width: 125px;
        }
    }

    &.error {
        border-color: red;
    }
}

.age-check input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #000;
}

.age-check input:focus {
    outline: none;
}

.age-check input:last-child {
    margin-right: 0;
}

.age-check .btn {
    margin-top: 30px;
    background: $red;
    
    @include tablet {
        display: block;
        margin: 0 auto;
        margin-top: 50px;
        min-width: 322px;
    }
}