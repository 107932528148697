.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 6px 0px 2px;
  background: $blueDark;
  color: #fff;
  //   border-bottom: 1px solid #e1e1e1;
  z-index: 999;

  @include tablet {
    padding: 14px 0px 8px;
  }
}

.header__logo {
  float: left;
  max-width: 48px;

  svg {
    width: 180px;
  }

  @include tablet {
    max-width: 68px;
  }
}

.header__nav {
  position: absolute;
  top: 60px;
  left: 0;
  padding-left: 16px;
  padding-top: 0px;
  width: 100%;
  background: $blueDark;
  height: 0px;
  overflow: hidden;
  transition: 0.3s ease;

  &.active {
    padding-bottom: 16px;
    transition: 0.3s ease;
    height: 220px;
    height: 100vh;

    @include tablet {
      height: auto;
      padding-bottom: 0;
    }
  }

  @include tablet {
    height: auto;
    overflow: visible;
    width: auto;
    padding-left: 0;
    padding-bottom: 0;
    top: 0;
    position: relative;
    display: inline-block;
    float: right;
  }

  ul {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-right: 26px;
      width: 100%;

      @include tablet {
        width: auto;
      }

      @include laptop {
        margin-right: 46px;
      }

      a {
        color: #fff;
      }

      // @media (max-width: 600px) {
      //     display: none;

      //     &:first-child {
      //         display: inline-block;
      //         margin-right: 0;
      //     }
      // }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.header__navTrigger {
  position: absolute;
  top: 22px;
  right: 14px;
  width: 30px;
  height: 26px;

  @include tablet {
    display: none;
  }

  &.active {
    .line1 {
      transform: rotate(45deg);
      top: 10px;
    }

    .line3 {
      transform: rotate(-45deg);
      top: 10px;
    }

    .line2 {
      opacity: 0;
    }
  }

  a {
    position: absolute;
    width: 100%;
    height: 100%;

    span {
      position: absolute;
      width: 100%;
      height: 4px;
      background: $red;
      top: 0;
      left: 0;

      &.line2 {
        top: 10px;
      }
      &.line3 {
        top: 20px;
      }
    }
  }
}

.header__nav__item {
  float: left;
}

.header__nav__link {
  display: inline-block;
  color: #fff;
  // font-family: $font-nonbase-black;
  text-transform: uppercase;
  font-family: $font-nonbase-regular;
  font-weight: bold;
  padding-top: 18px;
  padding-left: 14px;
  font-size: 20px;

  @include tablet {
    padding-left: 0px;
    font-size: 18px;
    padding-top: 20px;
  }

  &:hover {
    color: $red;
  }
}
